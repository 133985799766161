import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, NavLink, Switch } from "react-router-dom";
import Amplify, { Auth } from "aws-amplify";
import {
  ConfirmSignIn,
  ConfirmSignUp,
  ForgotPassword,
  RequireNewPassword,
  SignIn,
  VerifyContact,
  withAuthenticator,
} from "aws-amplify-react";
import UpdatesPage from "./UpdatesPage";
import ApiTestPage from "./ApiTestPage";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { config } from "./aws_config";
import AuthTheme from "./auth/AuthTheme";
import IntakesPage from "./intake/IntakesPage";
import AlertsPage from "./AlertsPage";
import ReportAccessPage from "./ReportsPage";
import { getCurrentAuthenticatedUser } from "./store/actions";
import { withMixpanel } from "react-mixpanel-browser";
import { idleTimerSecs } from "./constants";
import "./css/loading-btn.css";
import "./css/style.css";
import "./css/site.css";
import FacilityPage from "./FacilityPage";
import { QualityIncentivePage } from "./QualityIncentivePage";
import { useIdleTimer } from "react-idle-timer";

Amplify.configure(config);

export const App = (props) => {
  const { userEmail = null, mixpanel } = props;
  const [userFacilities, setUserFacilities] = useState(null);
  useEffect(() => {
    const { getCurrentAuthenticatedUser, mixpanel } = props;
    typeof getCurrentAuthenticatedUser === "function" &&
      getCurrentAuthenticatedUser().then((data) => {
        const { value: { attributes, signInUserSession: { accessToken: { jwtToken } = {} } = {} } = {} } = data;

        setUserFacilities(attributes["custom:facilityIds"].split(","));

        if (jwtToken && attributes.email) {
          mixpanel.identify(attributes.email);
          mixpanel.track("user log in", { email: attributes.email });
        }
      });
  }, []);

  const handleLogout = () => {
    Auth.signOut();
  };

  useIdleTimer({
    events: ["load", "mousemove", "mousedown", "click", "scroll", "keypress"],
    timeout: idleTimerSecs * 1000,
    onIdle: handleLogout,
    debounce: 500,
    enabled: !process.env.REACT_APP_IS_LOCAL,
  });

  const doLink = (path) => {
    const { baseRoute = "" } = props;
    return `${baseRoute}${path}`;
  };

  const showQualityIncentiveLink = () => {
    const qipFacilities = process.env.REACT_APP_FT_QIP.split(" ");
    return userFacilities ? userFacilities.some((x) => qipFacilities.includes(x)) : false;
  };

  return (
    <Router>
      <>
        <div id="header" className="clearfix">
          <div className="color-line" />
          <nav className="navbar navbar-default" role="navigation">
            <div className="navbar-header">
              <a href="/" className="navbar-brand">
                <img src="/static/images/medasync-logo.svg" alt="Medasync Logo" />
              </a>
            </div>
            <div className="header-nav navbar-collapse" id="Navbar">
              <ul className="nav navbar-nav navbar-right no-borders">
                {userEmail && (
                  <li>
                    <p className="p-sm">Hello, {userEmail}</p>
                  </li>
                )}
                <li>
                  <a className="p-sm" href="mailto:support@medasync.com?subject=Contact Us">
                    Contact Support
                  </a>
                </li>
                <li>
                  <button
                    className="btn btn-primary m-t-sm m-b-sm m-r m-l"
                    style={{
                      color: "white",
                    }}
                    onClick={() => {
                      handleLogout();
                      mixpanel.reset();
                    }}
                  >
                    Sign Out
                  </button>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <nav id="menu">
          <ul className="nav" id="side-menu" role="menu">
            <li role="menuitem">
              <NavLink exact to={doLink("/updates")} activeClassName={"active"}>
                <i className="fa fa-fw fa-flag" />
                Updates
              </NavLink>
            </li>
            <li role="menuitem">
              <NavLink to={doLink("/intakes")} activeClassName={"active"}>
                <i className="fa fa-fw fa-user-plus" />
                Intake
              </NavLink>
            </li>
            <li role="menuitem">
              <NavLink to={doLink("/patients")} activeClassName={"active"}>
                <i className="fa fa-fw fa-user" />
                Patients
              </NavLink>
            </li>
            <li role="menuitem">
              <NavLink to={doLink("/alerts")} activeClassName={"active"}>
                <i className="fa fa-fw fa-bell" />
                Alerts
              </NavLink>
            </li>
            <li role="menuitem">
              <NavLink to={doLink("/reports")} activeClassName={"active"}>
                <i className="fa fa-fw fa-bar-chart" />
                Analytics
              </NavLink>
            </li>
            {showQualityIncentiveLink() && (
              <li role="menuitem">
                <NavLink to={doLink("/quality-incentive-tracking")} activeClassName={"active"}>
                  <i className="fa fa-fw fa-users" />
                  QIP
                </NavLink>
              </li>
            )}
            {process.env.REACT_APP_IS_LOCAL && (
              <li role="menuitem">
                <NavLink to={doLink("/apitest")} activeClassName={"active"}>
                  <i className="fa fa-fw fa-close" />
                  Api Test
                </NavLink>
              </li>
            )}
          </ul>
        </nav>

        <div id="Wrapper">
          <main className="content">
            <Switch>
              <Route path={doLink("/")} exact component={AlertsPage} />
              <Route path={doLink("/updates")} exact component={UpdatesPage} />
              <Route path={doLink("/alerts")} exact component={AlertsPage} />
              <Route path={doLink("/reports")} component={ReportAccessPage} />
              <Route path={doLink("/quality-incentive-tracking")} component={QualityIncentivePage} />
              <Route path={doLink("/intakes")} component={IntakesPage} />
              <Route path={doLink("/patients")} component={FacilityPage} />
              {process.env.REACT_APP_IS_LOCAL && <Route path={doLink("/apitest")} component={ApiTestPage} />}
            </Switch>
          </main>
          <footer>
            <span>{"\u00A9"} 2018 - MedaSync </span>
          </footer>
        </div>
      </>
    </Router>
  );
};

App.propTypes = {
  baseRoute: PropTypes.string,
};

function mapStateToProps(state) {
  const { user = {} } = state;
  return {
    userEmail: user.email,
  };
}

export default connect(mapStateToProps, { getCurrentAuthenticatedUser })(
  withAuthenticator(
    withMixpanel(App),
    false,
    [<ConfirmSignIn />, <ConfirmSignUp />, <ForgotPassword />, <RequireNewPassword />, <SignIn />, <VerifyContact />],
    null,
    AuthTheme
  )
);
